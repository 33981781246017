import React from "react"
import SEO from "../../../../components/seo"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import InformationSecurityPolicyMobile from "../../../../components/body/pages/mobile/ng/legal/information-security"

const InformationSecurityPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/information-security-policy"}
      title="Information Security Policy Kuda | Kuda | The Money App for Africans"
      description="This information security policy establishes a set of measures that must be uniformly applied across Kuda to ensure a secured operating environment."
    />
    <InformationSecurityPolicyMobile />
  </NoheaderFooter>
)

export default InformationSecurityPage
